export default class BuildInfo {
  readonly appVersion = process.env.APP_VERSION

  readonly nodeEnv = process.env.NODE_ENV

  readonly buildTime = process.env.BUILD_TIME

  readonly gitCommit = process.env.GIT_COMMIT

  readonly ciBuildTime = process.env.CI_BUILD_TIME

  readonly ciBuildId = process.env.CI_BUILD_ID

  readonly isModernBuild = process.env.IS_MODERN_BUILD
}
