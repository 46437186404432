







































import Vue from 'vue'
import Component from 'vue-class-component'
import BuildInfo from '@/util/BuildInfo'

@Component
export default class Version extends Vue {
  private buildInfo = new BuildInfo()

  formattedDateFromTime(time: number): string {
    if (!time) {
      return ''
    }
    return new Date(time).toLocaleString()
  }
}
